<template>
  <div class="main">
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="publishableKey"
      :line-items="lineItems"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="v => loading = v"
    />
    <img @click="submit" src="/front.png">
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
export default {
  name: 'HelloWorld',
  components: {
    StripeCheckout,
  },
  data () {
    this.publishableKey = "pk_test_51IuDoxJqYJgEbNd44H5rpANbfBclpOmhHgFvRjlDX0ddm1YgsShA12nteF4wGbzPy3sjvDEGQxggwCFvnMORTqPf00JlaoXLYB";
    return {
      loading: false,
      lineItems: [
        {
          price: 'price_1IxsXhJqYJgEbNd4QmjEg4JW', // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: 'https://payment-sample.onrender.com',
      cancelURL: 'https://payment-sample.onrender.com',
    };
  },
  methods: {
    submit () {
      // You will be redirected to Stripe's secure checkout page
      console.log(this.$refs.checkoutRef.redirectToCheckout());
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
